.custom-select-container {
  display: flex;
  flex-direction: column;
  font-family: 'Muli';
  width: 100%;
  gap: 4px;
}

.custom-select-wrapper {
  display: flex;
  flex-direction: column;  
  width: 100%;
  gap: 4px;
  justify-content: flex-end;
}

.custom-select-wrapper-small {
  height: 54px;
}
.custom-select-wrapper-medium {
  height: 69px;
}
.custom-select-wrapper-large {
  height: 83px;
}

.custom-select-container-disabled {
  cursor: not-allowed;
}

.custom-select-error-message {
  color: #c11414;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 2px;
}

.custom-select-error-icon {
  width: 16px;
  height: 16px;
}

.custom-select-label-small {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  color: #66797f;
  gap: 2px;
}

.custom-select-label-medium {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #66797f;
  gap: 2px;
}

.custom-select-label-large {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  color: #66797f;
  gap: 2px;
}

.custom-select-label-optional {
  color: #95a6aa;
  font-weight: 400;
  font-style: italic;
}

.custom-select-label-required {
  font-weight: 700;
  color: #000;
}
