.tab-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  color: #66797f;
  font-family: 'Muli';
  font-size: 14px;
  font-weight: 400;
  border-bottom: 4px solid transparent;
  cursor: pointer;
}

.tab-container:hover {
  border-bottom: 4px solid #aebbc0;
}

.tab-container-active {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: 'Muli';
  font-size: 14px;
  cursor: pointer;
  border-bottom: 4px solid #31d0bb;
  font-weight: 700;
  color: #3d4548;
  padding: 6px 12px;
}

.tab-container-disabled {
  color: #dde1e3;
  cursor: not-allowed;
}
