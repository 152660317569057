.commandstatus {
  display: flex;
  gap: 7px;
  align-items: center;
}

.commandstatus-row {
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
}

.commandstatuses-timeago {
  font-family: 'Muli';
  font-size: 10px;
  font-weight: 400;
}

.commandstatus-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
