.userManagement-container {
  height: calc(100vh - 60px);
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.userManagement-container .warning {
  padding: 10px;
  background-color: #999999;
  font-size: 13px;
  color: white;
}

.userManagement-headerContent {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0 10px;
}

.userManagement-headLeft {
  display: flex;
  flex: 1;
}

.userManagement-headRight {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.userManagement-headerTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 20px;
  font-family: 'Muli', sans-serif;
}

.userManagement-importUsersButton {
  padding: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 130px;
  height: 41px;
  border-radius: 2px;
  border: solid 1px #ddd;
  background-color: white;
  font-family: 'Muli', sans-serif;
  margin-right: 5px;
  margin-left: 30px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #2b2e31;
}

::placeholder {
  color: #596172;
}

.userManagement-tableRowStyle:nth-child(even) {
  background-color: #f5f7fb;
}

.userManagement-columnWrapper {
  display: flex;
}

.userManagement-columnSortableTitle {
  padding-right: 10px;
  cursor: pointer;
}

.userManagement-columnSortIcon {
  cursor: pointer;
}

.userManagement-stickyColumn {
  position: sticky;
  right: 0;
  top: 0;
  border-left: 1px solid var(--secondary-brand);
  font-family: Muli;
  font-size: 13px;
  font-weight: 700;
  text-align: left;
  color: rgb(43, 46, 49);
  background-color: rgb(224, 228, 236);
  white-space: nowrap;
}

.userManagement-addRemoveGroupsButton {
  padding: 7px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-basis: 135px;
  height: 41px;
  border-radius: 2px;
  border: solid 1px #ddd;
  background-color: white;
  font-family: 'Muli', sans-serif;
  margin: 0 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.userManagement-addRemoveGroupsButton:disabled {
  cursor: default;
}

.userManagement-top-buttons-container {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
