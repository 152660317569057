.custom-input-main-container {
  display: flex;
  flex-direction: column;
  font-family: 'Muli';
  width: 100%;
  gap: 4px;
}

.custom-input {
  display: flex;
  justify-content: space-between;
  margin: 0;
  border: 0;
  width: 100%;
  outline: none;
  background-color: transparent;
}

.custom-input-error {
  background-color: #fff1f1;
  color: #c11414;
}

.custom-input-small {
  padding: 8px 12px;
  border-radius: 4px;
}

.custom-input-medium {
  padding: 10px 16px;
  border-radius: 6px;
}

.custom-input-large {
  padding: 14px 20px;
  border-radius: 6px;
}

.custom-input-container {
  display: flex;
  color: #3d4548;
  border: 1px solid #7c9098;
  background-color: white;
}

.custom-input-container-withSelect {
  border-right: none;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.custom-input-image-right {
  padding-right: 0;
}

.custom-input-image-left {
  padding-left: 0;
}

.custom-input-container:hover {
  border: 2px solid #3d4548;
  background-color: #f4f6f7;
}

.custom-input-container-error {
  border: 1px solid #841818;
  background-color: #fff1f1;
}

.custom-input-container-small:hover {
  height: 30px;
}
.custom-input-container-medium:hover {
  height: 42px;
}
.custom-input-container-large:hover {
  height: 54px;
}

.custom-input-container-small {
  /* gap: 8px; */
  height: 32px;
  border-radius: 4px;
  font-size: 14px;
  /* padding: 0px 12px; */
}

.custom-input-container-medium {
  height: 44px;
  /* gap: 8px; */
  border-radius: 6px;
  font-size: 16px;
  /* padding: 0px 16px; */
}

.custom-input-container-large {
  /* gap: 12px; */
  height: 56px;
  border-radius: 6px;
  font-size: 20px;
  /* padding: 0px 20px; */
}

.custom-input-label-small {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  color: #66797f;
  gap: 2px;
}

.custom-input-label-medium {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #66797f;
  gap: 2px;
}

.custom-input-label-large {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  color: #66797f;
  gap: 2px;
}

.custom-input-label-optional {
  color: #95a6aa;
  font-weight: 400;
  font-style: italic;
}

.custom-input-label-required {
  font-weight: 700;
  color: #000;
}

.custom-input-helptext {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  color: #66797f;
}

.custom-input-error-message {
  color: #c11414;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 2px;
}

.custom-input-error-icon {
  width: 16px;
  height: 16px;
}

.custom-input-disabled {
  opacity: 30%;
  cursor: not-allowed;
}

.custom-input-img-small {
  width: 16px;
  /* height: 16px; */
  align-self: center;
  margin: 0 8px;
}

.custom-input-img-medium {
  width: 24px;
  /* height: 24px; */
  align-self: center;
  margin: 0 8px;
}

.custom-input-img-large {
  width: 28px;
  /* height: 28px; */
  align-self: center;
  margin: 0 10px;
}
