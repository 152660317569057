.userManagement-createUserModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 722px;
}

.userManagement-createUserTitle {
  font-family: 'Muli';
  font-size: 16pt;
  font-weight: 700;
  padding-top: 36px;
  padding-left: 63px;
}

.userManagement-createUserBody {
  display: flex;
  flex-direction: column;
  width: 618px;
}

.userManagement-enterEmail {
  margin-bottom: 15px;
}

.userManagement-inputLabel {
  font-size: 14px;
  font-weight: 600;
  color: #767676;
  padding-bottom: 2px;
}

.userManagement-userInformationTitle {
  font-family: 'Muli';
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
}

.userManagement-userEmail {
  margin: 0;
}

.userManagement-userTypeContainer {
  display: flex;
  flex-direction: column;
}

.userManagement-group-container {
  overflow: scroll;
}

.userManagement-userTypeOptions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
}

.userManagement-checkbox {
  display: flex;
  flex-direction: column;
}

.userManagement-buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 116px;
  margin-bottom: 52px;
  margin-top: 76px;
}

.userManagement-errorMessage {
  font-family: 'Muli';
  font-size: 10pt;
  color: #cc0000;
}

.userManagement-createButton {
  background-color: var(--primary-brand);
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  font-family: 'Muli';
  font-size: 10pt;
  line-height: 12pt;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  width: 128px;
  margin-right: 7px;
  cursor: pointer;
}

.userManagement-createButtonDisabled {
  background-color: #d1d1d1;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  font-family: 'Muli';
  font-size: 10pt;
  line-height: 12pt;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  width: 128px;
  margin-right: 7px;
}

.userManagement-cancelButton {
  background-color: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  font-family: 'Muli';
  font-size: 10pt;
  line-height: 12pt;
  padding-top: 10px;
  padding-bottom: 10px;
  color: black;
  width: 128px;
  cursor: pointer;
}

.userManagement-error-container {
  display: flex;
  align-items: center;
  background-color: #fff3f3;
  border: 1px solid #c11414;
  border-left: 5px solid #c11414;
  padding: 10px;
  gap: 10px;
}

.userManagement-error-title {
  font-weight: bold;
  font-size: 13px;
}

.userManagement-error-details {
  font-size: 11px;
  color: #2e3236;
}

.userManagement-error-message {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.userManagement-modalTab {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  justify-content: space-around;
  padding-bottom: 23px;
  padding-top: 23px;
}

.userManagement-errorMsg {
  color: red;
  font-size: 13px;
}

.modal-container {
  font-family: 'Muli';
  padding: 32px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 24px;
}

.modal-button-container {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding: 32px;
  font-family: 'Muli';
  font-size: 16px;
  font-weight: 700;
  padding-top: 0px;
}
