.alert-dialog {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 30px;
}

.alert-dialog .title {
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
}

.alert-dialog .content {
  font-family: Muli, sans-serif;
  margin-top: 5px;
  font-size: 14px;
  max-width: 500px;
  text-align: center;
  overflow-wrap: break-word;
}

.alert-dialog .actions {
  display: flex;
  flex: 1;
  width: 100%;
  gap: 10px;
}

.alert-dialog .modal-icon {
  color: #ffa500;
  width: 40px;
  height: 40px;
}

.alert-dialog .actions button {
  display: flex;
  flex: 1;
  font-family: Muli, sans-serif;
  border-radius: 4px;
  margin-top: 20px;
}
