.customFilter-searchContainer {
  width: 100%;
  height: 18px;
  display: flex;
  border: 1px solid #181d1f;
  border-radius: 3px;
  align-self: center;
}

.customFilter-searchIcon {
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9b9b9b;
  background-color: white;
  padding-right: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.customFilter-searchInput {
  width: 100%;
  padding: 1px 2px 1px 6px;
  border: none;
  outline: none;
  font-family: 'Muli', sans-serif;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
