.switch-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin: 8px;
  font-family: 'Muli';
}

.checked-handle-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  pointer-events: none;
}

.switch-label-unchecked {
  color: #66797f;
}

.switch-container-disabled {
  cursor: not-allowed;
}

.switch-label-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.switch-label {
  font-weight: 600;
  font-size: 16px;
}

.switch-label-medium {
  font-weight: 600;
  font-size: 20px;
}

.switch-description {
  font-weight: 400;
  font-size: 12px;
  color: #66797f;
}

.switch-description-medium {
  font-weight: 400;
  font-size: 14px;
  color: #66797f;
}
