.tree-view {
    width: max-content;
    padding: 0 10px 0 10px;
    font-family: 'Muli', sans-serif;
}

.tree-view .node {
    display: flex;
    flex-direction: row;
    color: #333333;
}
.tree-view .node .hidden {
    visibility: hidden;
}

.tree-view .node .disabled {
  color: #D1D1D1;
}