.loadingCircle {
  animation: rotation 1s linear infinite;
}

.alignScreenCenter {
  position: fixed;
  top: 50%;
  right: 50%;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
