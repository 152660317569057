.checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  font-family: 'Muli';
}

.checkbox-label-checked {
  font-weight: 600 !important;
  color: #000 !important;
}

.checkbox-container-disabled {
  cursor: not-allowed;
}

.checkbox-label-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}

.checkbox-label-large {
  font-weight: 600;
  font-size: 20px;
  color: #3d4548;
}
.checkbox-label-medium {
  font-weight: 400;
  font-size: 16px;
  color: #3d4548;
}
.checkbox-label-small {
  font-weight: 400;
  font-size: 14px;
  color: #3d4548;
}

.checkbox-description {
  font-weight: 400;
  font-size: 12px;
  color: #66797f;
}

.checkbox-description-small {
  font-weight: 400;
  font-size: 12px;
  color: #66797f;
}

.checkbox-description-medium {
  font-weight: 400;
  font-size: 14px;
  color: #66797f;
}

.checkbox-description-large {
  font-weight: 400;
  font-size: 16px;
  color: #66797f;
}
