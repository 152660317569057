:root {
  --blue-brand-25: #E8F4FF;
  --blue-brand-50: #D5EBFF;
  --blue-brand-100: #B3D8FF;
  --blue-brand-200: #85BBFF;
  --blue-brand-300: #5690FF;
  --blue-brand-400: #2F65FF;
  --blue-brand-500: #0C34FF;
  --blue-brand-600: #0029FF;
  --blue-brand-700: #0628CD;
  --blue-brand-800: #102C9F;
  --blue-brand-900: #0A185C;

  --blue-sky-25: #EBFEFF;
  --blue-sky-50: #CDF9FF;
  --blue-sky-100: #A1F1FF;
  --blue-sky-200: #5CE3FF;
  --blue-sky-300: #1ACDF6;
  --blue-sky-400: #00B0DC;
  --blue-sky-500: #018BB9;
  --blue-sky-600: #096F95;
  --blue-sky-700: #115A79;
  --blue-sky-800: #134A66;
  --blue-sky-900: #063046;

  --indigo-blue-25: #F1F7FD;
  --indigo-blue-50: #DFEEFA;
  --indigo-blue-100: #C5E1F8;
  --indigo-blue-200: #9ECFF2;
  --indigo-blue-300: #70B4EA;
  --indigo-blue-400: #4E96E3;
  --indigo-blue-500: #397AD7;
  --indigo-blue-600: #3066C5;
  --indigo-blue-700: #2D54A0;
  --indigo-blue-800: #29487F;
  --indigo-blue-900: #121C30;

  --orange-25:  #FFF4EB;
  --orange-50:  #FFE0C2;
  --orange-100: #FFBF82;
  --orange-200: #FF9A3B;
  --orange-300: #F47700;
  --orange-400: #E16E03;
  --orange-500: #B65800;
  --orange-600: #A14E00;
  --orange-700: #864100;
  --orange-800: #5C2D00;
  --orange-900: #2D1702;

  --metallic-grey-25: #F3F5F6;
  --metallic-grey-50: #EDF0F1;
  --metallic-grey-100: #DCE1E3;
  --metallic-grey-200: #C3CBCF;
  --metallic-grey-300: #ADBABF;
  --metallic-grey-400: #94A6A9;
  --metallic-grey-500: #7C8F97;
  --metallic-grey-600: #66797F;
  --metallic-grey-700: #5F6E75;
  --metallic-grey-800: #556166;
  --metallic-grey-900: #3D4448;

  --red-25: #FFF1F1;
  --red-50: #FFE1E1;
  --red-100: #FFC7C7;
  --red-200: #FFA0A0;
  --red-300: #FF6B6B;
  --red-400: #F83B3B;
  --red-500: #E51D1D;
  --red-600: #C11414;
  --red-700: #A01414;
  --red-800: #841818;
  --red-900: #480707;

  --yellow-25: #FEFBE8;
  --yellow-50: #FFF5C2;
  --yellow-100: #FFE46B;
  --yellow-200: #FFD645;
  --yellow-300: #FCBF13;
  --yellow-400: #ECA606;
  --yellow-500: #CC7F02;
  --yellow-600: #A25806;
  --yellow-700: #86460D;
  --yellow-800: #723911;
  --yellow-900: #431C05;

  --primary-brand: var(--blue-brand-900) !important;
  --secondary-brand: var(--blue-brand-700) !important;
  --primary-header-brand: var(--blue-brand-900) !important;
  --secondary-header-brand: var(--blue-brand-700) !important;
  --tertiary-header-brand: #ffffff !important;
  --primary-icon-brand: #ffffff !important;
  --secondary-icon-brand: var(--blue-brand-900) !important;
  --tertriary-icon-button: var(--blue-brand-900) !important;
  --primary-highlight-brand: #f6f7ff !important;
  --primary-button-brand: #5b7f9b !important;
  --primary-logo-line: #ffffff !important;


}
