.vertical-menu {
  display: flex;
  width: 234px;
  background-color: var(--primary-header-brand);
  flex-direction: column;
  z-index: 999;
}

.vertical-menu-minimized {
  display: flex;
  width: 72px;
  background-color: var(--primary-header-brand);
  flex-direction: column;
}

.vertical-menu-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  background-color: var(--blue-brand-700);
}

.vertical-menu-user {
  display: flex;
  justify-content: center;
  padding: 24px 0px;
  gap: 8px;
}

.vertical-menu-user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
  font-family: 'Muli';
  font-size: 12px;
  color: white;
}

.vertical-menu-user-name {
  font-weight: 700;
}

.vertical-menu-user-text {
  color: #dde1e3;
}

.menu-button {
  display: flex;
  padding: 12px 24px;
  gap: 8px;
  border-bottom: 1px solid var(--indigo-blue-700);
  cursor: pointer;
  color: var(--indigo-blue-100);
  font-weight: 400;
  text-decoration: none;
  user-select: none;
}

.menu-button-active {
  display: flex;
  padding: 12px 24px;
  gap: 8px;
  border-bottom: 4px solid var(--blue-sky-300);
  cursor: pointer;
  color: var(--blue-brand-900);
  font-weight: 800;
  font-size: 16px;
  background-color: var(--indigo-blue-25);
  text-decoration: none;
}

.menu-button-text {
  font-family: 'Muli';
  font-size: 16px;
}

.menu-subbutton-text {
  font-family: 'Muli';
  font-size: 12px;
  padding-left: 28px;
}

.menu-button:hover {
  padding: 12px 24px 12px 24px;
  color: white;
  font-weight: 700;
}

.vertical-menu-left {
  color: white;
  cursor: pointer;
  margin-left: 16px;
}

.vertical-menu-right {
  color: white;
  cursor: pointer;
}

.menu-settings {
  display: flex;
}
