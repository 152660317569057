@import '../variables.css';

/*
 * normalize.css is imported in JS file.
 * If you import external CSS file from your internal CSS
 * then it will be inlined and processed with CSS modules.
 */

/*
 * Base styles
 * ========================================================================== */
.layout-root {
  /* min-width: 1024px; */
  min-height: var(--content-height);
  background-color: #ebebeb;
  display: flex;
  box-sizing: border-box;
}

.layout-root-children {
  display: flex;
  box-sizing: border-box;
  flex: 1;
}

.layout-root-menu {
  display: flex;
  box-sizing: border-box;
}

@media screen and(max-width: 1024px) {
  .layout-root {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    position: relative;
    overflow: auto;
  }
}

body {
  margin: 0;
}

a {
  color: #0074c2;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
  resize: vertical;
}

/*
 * Browser upgrade prompt
 * ========================================================================== */

:global(.browserupgrade) {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/*
 * Print styles
 * Inlined to avoid the additional HTTP request:
 * http://www.phpied.com/delay-loading-your-print-css/
 * ========================================================================== */

@media print {
  *,
  *::before,
  *::after {
    background: transparent !important;
    color: #000 !important;

    /* Black prints faster: http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: ' (' attr(href) ')';
  }

  abbr[title]::after {
    content: ' (' attr(title) ')';
  }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
  a[href^='#']::after,
  a[href^='javascript:']::after {
    content: '';
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

@font-face {
  font-family: 'Muli';
  src: local('Muli Black'), local('Muli-Black'), url('../../assets/fonts/Muli-Black.woff2') format('woff2'),
    url('../../assets/fonts/Muli-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: local('Muli ExtraLight Italic'), local('Muli-ExtraLightItalic'), url('../../assets/fonts/Muli-ExtraLightItalic.woff2') format('woff2'),
    url('../../assets/fonts/Muli-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: local('Muli ExtraBold'), local('Muli-ExtraBold'), url('../../assets/fonts/Muli-ExtraBold.woff2') format('woff2'),
    url('../../assets/fonts/Muli-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: local('Muli ExtraLight'), local('Muli-ExtraLight'), url('../../assets/fonts/Muli-ExtraLight.woff2') format('woff2'),
    url('../../assets/fonts/Muli-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: local('Muli Bold'), local('Muli-Bold'), url('../../assets/fonts/Muli-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Muli-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: local('Muli Light'), local('Muli-Light'), url('../../assets/fonts/Muli-Light.woff2') format('woff2'),
    url('../../assets/fonts/Muli-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: local('Muli SemiBold Italic'), local('Muli-SemiBoldItalic'), url('../../assets/fonts/Muli-SemiBoldItalic.woff2') format('woff2'),
    url('../../assets/fonts/Muli-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: local('Muli Bold Italic'), local('Muli-BoldItalic'), url('../../assets/fonts/Muli-BoldItalic.woff2') format('woff2'),
    url('../../assets/fonts/Muli-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: local('Muli Light Italic'), local('Muli-LightItalic'), url('../../assets/fonts/Muli-LightItalic.woff2') format('woff2'),
    url('../../assets/fonts/Muli-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: local('Muli Black Italic'), local('Muli-BlackItalic'), url('../../assets/fonts/Muli-BlackItalic.woff2') format('woff2'),
    url('../../assets/fonts/Muli-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: local('Muli ExtraBold Italic'), local('Muli-ExtraBoldItalic'), url('../../assets/fonts/Muli-ExtraBoldItalic.woff2') format('woff2'),
    url('../../assets/fonts/Muli-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: local('Muli Regular'), local('Muli-Regular'), url('../../assets/fonts/Muli-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Muli-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: local('Muli Italic'), local('Muli-Italic'), url('../../assets/fonts/Muli-Italic.woff2') format('woff2'),
    url('../../assets/fonts/Muli-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: local('Muli SemiBold'), local('Muli-SemiBold'), url('../../assets/fonts/Muli-SemiBold.woff2') format('woff2'),
    url('../../assets/fonts/Muli-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
