.fp-button {
  border-radius: 6px;
  user-select: none;
  font-family: 'Muli';
  font-weight: 700;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fp-button-small {
  padding: 7px 16px;
  font-size: 14px;
  gap: 6px;
}

.fp-button-medium {
  padding: 12px 20px;
  font-size: 16px;
  gap: 8px;
}

.fp-button-large {
  padding: 13px 24px;
  font-size: 24px;
  gap: 8px;
}

.fp-button-blue {
  background-color: var(--secondary-brand);
  border: 2px solid var(--secondary-brand);
  color: white;
}

.fp-button-blue:hover {
  background-color: var(--primary-brand);
  border: 2px solid var(--primary-brand);
  color: white;
}

.fp-button-blue:focus {
  background-color: var(--primary-brand);
  border: 2px solid var(--primary-brand);
  color: white;
}

.fp-button-blue:active {
  background-color: var(--blue-brand-100);
  border: 2px solid var(--primary-brand);
  color: var(--primary-brand);
}

.fp-button-blue:disabled {
  background-color: var(--secondary-brand);
  border: 2px solid var(--secondary-brand);
  ;
  opacity: 0.2;
  cursor: default;
  color: white;
}

.fp-button-secondary {
  background-color: var(--blue-brand-100);
  border: 2px solid var(--blue-brand-100);
  color: var(--primary-brand);
}

.fp-button-secondary:hover {
  background-color: var(--secondary-brand);
  border: 2px solid var(--secondary-brand);
  color: white;
}

.fp-button-secondary:focus {
  background-color: var(--primary-brand);
  border: 2px solid var(--primary-brand);
  color: white;
}

.fp-button-secondary:active {
  background-color: var(--blue-brand-100);
  border: 2px solid var(--primary-brand);
  color: var(--primary-brand);
}

.fp-button-secondary:disabled {
  background-color: var(--blue-brand-100);
  border: 2px solid var(--blue-brand-100);
  color: var(--primary-brand);
  opacity: 0.2;
  cursor: default;
}


.fp-button-red {
  background-color: var(--red-600);
  border: 2px solid var(--red-600);
  color: white;
}

.fp-button-red:hover {
  background-color: var(--red-800);
  border: 2px solid var(--red-800);
}

.fp-button-red:focus {
  background-color: var(--red-800);
  border: 2px solid var(--red-900);
}

.fp-button-red:active {
  background-color: var(--red-600);
  ;
  border: 2px solid var(--red-900);
}

.fp-button-red:disabled {
  background-color: var(--red-600);
  border: 2px solid var(--red-600);
  color: white;
  opacity: 0.2;
  cursor: default;
}

.fp-button-blue-outline {
  background-color: transparent;
  border: 2px solid var(--blue-brand-800);
  color: var(--blue-brand-800);
}

.fp-button-blue-outline:hover {
  background-color: var(--blue-brand-100);
  border: 2px solid var(--blue-brand-100);
  color: var(--blue-brand-900);
}

.fp-button-blue-outline:focus {
  background-color: var(--blue-brand-100);
  border: 2px solid var(--blue-brand-900);
  color: var(--blue-brand-900);
}

.fp-button-blue-outline:active {
  background-color: var(--blue-brand-50);
  border: 2px solid var(--blue-brand-900);
  color: var(--blue-brand-900);
}

.fp-button-blue-outline:disabled {
  background-color: transparent;
  border: 2px solid var(--blue-brand-800);
  color: var(--blue-brand-800);
  opacity: 0.2;
  cursor: default;
}

.fp-button-white-outline {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.fp-button-white-outline:hover {
  background-color: var(--metallic-grey-500);
  border: 2px solid var(--metallic-grey-500);
  color: white;
}

.fp-button-white-outline:focus {
  background-color: var(--metallic-grey-100);
  border: 2px solid var(--metallic-grey-900);
  color: var(--metallic-grey-900);
}

.fp-button-white-outline:active {
  background-color: var(--metallic-grey-200);
  border: 2px solid var(--metallic-grey-900);
  color: var(--metallic-grey-900);
}

.fp-button-white-outline:disabled {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  opacity: 0.2;
  cursor: default;
}

.fp-button-ghost-neutrals {
  background-color: transparent;
  border: 2px solid transparent;
  color: var(--metallic-grey-900);
}

.fp-button-ghost-neutrals:hover {
  background-color: var(--metallic-grey-100);
  border: 2px solid var(--metallic-grey-100);
  color: var(--metallic-grey-900);
}

.fp-button-ghost-neutrals:focus {
  background-color: var(--metallic-grey-100);
  ;
  border: 2px solid var(--metallic-grey-900);
  ;
  color: var(--metallic-grey-900);
}

.fp-button-ghost-neutrals:active {
  background-color: var(--metallic-grey-200);
  ;
  border: 2px solid var(--metallic-grey-900);
  ;
  color: var(--metallic-grey-900);
}

.fp-button-ghost-neutrals:disabled {
  background-color: transparent;
  border: 2px solid transparent;
  color: var(--metallic-grey-900);
  opacity: 0.2;
  cursor: default;
}

.fp-button-ghost-white {
  background-color: transparent;
  border: 2px solid transparent;
  color: white;
}

.fp-button-ghost-white:hover {
  background-color: var(--metallic-grey-400);
  border: 2px solid var(--metallic-grey-400);
  color: white;
}

.fp-button-ghost-white:focus {
  background-color: var(--metallic-grey-100);
  border: 2px solid white;
  color: white;
}

.fp-button-ghost-white:active {
  background-color: var(--metallic-grey-600);
  border: 2px solid white;
  color: white;
}

.fp-button-ghost-white:disabled {
  background-color: transparent;
  border: 2px solid transparent;
  color: white;
  opacity: 0.2;
  cursor: default;
}

.fp-button-link {
  background-color: transparent;
  border: 2px solid transparent;
  color: var(--blue-brand-700);
  text-decoration: underline;
}

.fp-button-link:hover {
  background-color: var(--blue-brand-25);
  border: 2px solid var(--blue-brand-25);
  color: var(--blue-brand-800);
  text-decoration: none;
}

.fp-button-link:focus {
  background-color: var(--blue-brand-25);
  border: 2px solid var(--blue-brand-800);
  color: var(--blue-brand-800);
}

.fp-button-link:active {
  background-color: var(--blue-brand-50);
  border: 2px solid var(--blue-brand-900);
  color: var(--blue-brand-900);
}

.fp-button-link:disabled {
  background-color: transparent;
  border: 2px solid transparent;
  color: var(--blue-brand-700);
  text-decoration: underline;
  opacity: 0.2;
  cursor: default;
}